

.react-datepicker-popper,
.ui.selection.dropdown.active,
.ui.selection.dropdown.visible {
  z-index: 100;
}

.grid-header {
  font-weight: bold;
}

.rdw-editor-main {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  padding: 0em 1em;
  margin: 0;
}

.public-DraftStyleDefault-block {
  /* margin: 0; */
}

table .ui.label {
    border-radius: 0.15rem;
}

.ui.form .field>label,
.ui.form .inline.fields .field>label,
.ui.form .inline.fields>label {
  color: #878787;
  vertical-align: top;
}

.ui.form,
.ui.table.small-table {
  max-width: 768px !important;
  margin: auto;
}

.ui.blue.table {
  border-top: .2em solid #2185d0;
  /* border-bottom: .2em solid #2185d0; */
}

.rdw-editor-main {
  min-height: 200px;
}

.ui.container.login-page {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* width: max-content; */
  /* margin: auto;
  vertical-align: middle; */
}

/*#root {*/
/*  height: 100%;*/
/*}*/

/* .login-segment {
  vertical-align: middle !important;
  margin: auto !important;
} */

html, body, #root, .app, .pushable, .pusher, .appBody {
  min-height: 100vh;
}

.sidebarPushable, .pusher {
    transform: none !important;
}

.pusher {
    position: static !important;
}

.pusher.dimmed::after {
    position: fixed !important;
}

.appMenu {
  height: 40px !important;
  justify-content: space-between;
}
.appMenu .navDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.appMenu .navDiv .navContainer {
  display: flex;
}
.appMenu .navDiv .signOutDiv {

}
.appMenu button {
  border-radius: 0px!important;
  margin-right: 0px!important;
  height: 100%;
}

.appBody {
    padding: 50px 15px 80px;
}

.menuSidebar {
    width: 200px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1001 !important;
}
.menuSidebar::-webkit-scrollbar {
  display: none;
}

.menuSidebar .divider {
  margin: 0;
}
.menuSidebar .sideBarItem {
  display: flex!important;
  flex-direction: row!important;
  align-items: center;
}
#wmfdMenu > div.item.sideBarItem > h5 {
  margin-top: 0px;
}
#wmfdMenu > .sideBarHeader {
  font-weight: bold;
  color: rgb(33, 133, 207);
}
#wmfdMenu > a {
  padding: 9px;
  font-size: 13px;
}
#wmfdMenu > div.disabled.item.sideBarItem > i, #wmfdMenu > a.item.sideBarItem > i {
  margin: 0 15px 0 0 !important;
}
#wmfdMenu .sideBarItem i {
  font-size: 13px!important;
}
#wmfdMenu .sideBarItem h5 {
  font-size: 15px!important;
  margin-top: 0px;
  font-weight: normal;
}

.ui.form.image-form {
    max-width: 100% !important;
}

.electionHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.electionLists {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.dateRangeSegments {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.segmentContent {
  width: 45%;
}
.segmentContent:nth-child(2) {
  margin-top: 0px;
}
.candidateForm {
  display: flex;
  justify-content: center;
}
.voteForm {
  margin-top: 2px;
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 800px) {
  .electionHeading {
    flex-direction: column;
  }
  .electionHeaders {
    text-align: center;
  }
  .dateRangeSegments {
    margin: 10px auto;
  }
  .electionLists {
    flex-direction: column;
    align-items: center;
  }
  .segmentContent {
    width: 70%;
  }
}

@media screen and (max-width: 520px) {
  .candidateForm {
    margin-bottom: 20px!important;
  }
  .candidateForm .field:nth-child(1) {
    width: 150px;
  }
  .candidateForm button {
    margin-top: 8px!important;
  }

  .voteForm {
    margin-bottom: 20px!important;
  }
  .voteForm .field:nth-child(1) {
    width: 150px;
  }
  .voteForm button {
    margin-top: 8px!important;
  }
}

@media screen and (max-width: 500px) {
  .segmentContent {
    width: 100%;
    padding: 0px!important;
  }
  .segmentContent:nth-child(2) {
    margin-top: 20px;
  }
}
